import uniqid from 'uniqid'
import { projectsW, projectsG } from '../../portfolio'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'

const Projects = () => {
  if (!projectsW.length) return null

  return (
    <section id='projects' className='section projects'>
      <h2 className='section__title'>Works</h2>

      <h3 className='section__category'>Web Development</h3>

      <div className='projects__grid'>
        {projectsW.map((project) => (
          <ProjectContainer key={uniqid()} project={project} />
        ))}
      </div>
      
      <br />
      <h3 className='section__category'>Graphic Design</h3>
      <div className='projects__grid'>
        {projectsG.map((project) => (
          <ProjectContainer key={uniqid()} project={project} />
        ))}
        
      </div>
    </section>
  )
}

export default Projects
