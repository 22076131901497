import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faXTwitter, faLinkedin, faBehance } from '@fortawesome/free-brands-svg-icons'
import { faMugSaucer } from '@fortawesome/free-solid-svg-icons'
import { socials } from '../../portfolio'

const {twitter, behance, linkedin, github, kofi} = socials

const Footer = () => (


  <footer className='footer'>
    {twitter && (
      <a href={twitter} target='_Blank' rel="noreferrer" className='social-link'>
        <FontAwesomeIcon icon={faXTwitter}  size="1x"/>
      </a>
    )}

    {behance && (
      <a href={behance} target='_Blank' rel="noreferrer" className='social-link'>
        <FontAwesomeIcon icon={faBehance}  size="1x"/>
      </a>
    )}

    {linkedin && (
      <a href={linkedin} target='_Blank' rel="noreferrer" className='social-link'>
        <FontAwesomeIcon icon={faLinkedin}  size="1x"/>
      </a>
    )}

    {github && (
      <a href={github} target='_Blank' rel="noreferrer" className='social-link'>
        <FontAwesomeIcon icon={faGithub} size="1x"/>
      </a>
    )}

    {kofi && (
      <a href={kofi} target='_Blank' rel="noreferrer" className='social-link'>
        <FontAwesomeIcon icon={faMugSaucer} size="1x"/>
      </a>
    )}

    <br />
    <a href='https://github.com/piangspag' target='_Blank' className='link footer__link' rel="noreferrer">
      © 2024 Wilmar. All rights reserved. v2.1.3 <br/>
      Crafted with care by me – every line of code, every pixel.
    </a>
  </footer>
)

export default Footer
