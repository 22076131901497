import { useState } from 'react'
import { about } from '../../portfolio'
import './About.css'


const About = () => {
  const {photo, name, role, description} = about

  const [showNavList, setShowNavList] = useState(false)

  const toggleNavList = () => setShowNavList(!showNavList)

  return (
    <div className='about center'>
        <div className="about__Container">
          {photo && (
            <img alt="Avatar placeholder" src={photo} />
          )}          
        {name && (
          <h1>
            Hi, I am <span className='about__name'>{name}</span>
          </h1>
        )}

        {role && <h2 className='about__role'>{role}</h2>}
        <p className='about__desc'>{description && description}</p>

        <div className='about__contact center'>
            <a h href='#contact'
            onClick={toggleNavList}
            className='link link--nav'>
              <span type='button' className='btn btn--outline'>
                Hire me!
              </span>
            </a>
      

          
        </div>
      </div>
      
    </div>
  )
}

export default About
