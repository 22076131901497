// import aboutpic from "./components/Access/mePhoto.jpg"
import wilmarlogo from './assets/wilmarart.svg'
import enchante from './assets/Img/enchante.png'
import wfd from './assets/Img/wfd.png'
import towr from './assets/Img/towrlogo.png'


const header = {
  homepage: 'https://wilmarfuring.site/',
  title: 'Wilmar.',
}

const about = {
  photo:wilmarlogo,
  role: 'I love creating awesome websites!',
  description:
    'Ready to start a project?',
}

const projectsW = [
  {
    name: 'Service-based website',
    description:
      'The project is in progress, and I’m focused on creating a modern, user-friendly platform that meets their specific needs and goals. Stay tuned for the launch, and thank you for your patience and support!',
    stack: ['Reactjs', 'NodeJs', 'Tailwind', 'MongoDB'],
    sourceCode: '/',
    livePreview: '/',
  },
]

const projectsG = [
  {
    name: 'Enchante Flower',
    // img: enchante,
    description:
      'I crafted a refined, minimalistic logo and branding for a charming flower shop. The design features an elegant and simple color palette, highlighting the beauty of simplicity and sophistication in every petal.',
    stack: ['Illustrator', 'Canva'],
    behance: '/'
  },
  {
    name: 'WFD Repair',
    // img: wfd,
    description:
      'For the "WFD Logo and Banner" project, I developed a sleek, minimalistic design for a small appliance repair business. The focus was on clean lines and simplicity, reflecting the business’s dedication to efficiency and professionalism.',
    stack: ['Illustrator'],
    behance: 'https://www.behance.net/gallery/207025045/WFD-Logo-and-Banner/modules/1175600283'
  },
  {
    name: 'The Ones Who Remain',
    // img: towr,
    description:
      'I created a striking, minimalistic logo and banner for a zombie apocalyptic game server. The design features a blood-toned color palette, capturing a haunting, immersive atmosphere that perfectly complements the theme of survival.',
    stack: ['Illustrator'],
    behance: 'https://www.behance.net/gallery/207765289/GAME-SERVER-The-Ones-Who-Remain'
  },
]

const skillsW = [
  'Javascript',
  'ReactJS',
  'Vitejs',
  'Tailwind',
  'Bootstrap',
  'MongoDB',
  'Express',
  'Python',
  'NodeJS',
  'NextJS',
  'mySQL'
]

const skillsG = [
  'Illustrator',
  'Photoshop',
  'Figma',
  'Canva',
  'Lootie'

]

const socials = {
  twitter: 'https://x.com/wilmarfuring',
  behance: 'https://www.behance.net/furing',
  linkedin: 'https://www.linkedin.com/in/wilmar-furing/',
  github: 'https://github.com/piangspag',
  kofi: 'https://ko-fi.com/wilmarfuring'

}

const contact = {
  email: 'wilmar@wilmarfuring.site',
}

export { header, about, projectsW, projectsG, skillsW, skillsG, contact, socials }
