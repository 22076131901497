import uniqid from 'uniqid'
// import GitHubIcon from '@material-ui/icons/GitHub'
// import LaunchIcon from '@material-ui/icons/Launch'
import './ProjectContainer.css'
// import Behance from '@material-ui/icons/Facebook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareBehance, faGithub} from '@fortawesome/free-brands-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <h3>{project.name}</h3>
    <img src={project.img} alt="" width={200}/>
    <p className='project__description'>{project.description}</p>
    {project.stack && (
      <ul className='project__stack'>
        {project.stack.map((item) => (
          <li key={uniqid()} className='project__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {project.sourceCode && (
      <a
        href={project.sourceCode}
        aria-label='source code'
        className='link link--icon'
        target='_Blank' 
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} size="2x"/>

      </a>
    )}

    {project.livePreview && (
      <a
        href={project.livePreview}
        aria-label='live preview'
        className='link link--icon'
        target='_Blank' 
        rel="noreferrer"
      >
      
        <FontAwesomeIcon icon={faEye} size="2x"/>
      </a>
    )}

    {project.behance && (
      <a
        href={project.behance}
        aria-label='adobe behance'
        className='link link--icon'
        target='_Blank' 
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faSquareBehance} size="2x"/>
      </a>
    )}
  </div>
)

export default ProjectContainer
